import Store from './base';


export default class PostStore extends Store {
  constructor() {
    super('posts');
  }
}


