import css from './loading.css';

import React from 'react';


export default (props) => (
  <div className={css.container}>
    Loading...
  </div>
);
